<template>
    <div class="caption-1" ref="child" style="min-height: 440px; height: 100%">
        <div class="py-4 pr-4 pl-3">
            <v-row class="ma-0" no-gutters align="center">
                <v-col cols="auto" class="sub-title-2 font-weight-bold">
                    <v-row align="center" class="mx-0" no-gutters>
                        <v-col cols="auto" class="pr-4">
                            <v-icon size="20">mdi-alert-circle</v-icon>
                        </v-col>
                        <v-col>
                            <div>{{ $tc('global.name.activity', 2) }}</div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-spacer />
                <v-col cols="auto" v-if="activities.length > 0" class="pr-2">
                    <Button :iconColor="display === 'list' ? 'primary' : 'cell4'" :click="() => display = 'list'" iconVal="mdi-format-list-bulleted" carre color="cell5" />
                </v-col>
                <v-col cols="auto" v-if="activities.length > 0">
                    <Button :iconColor="display === 'calendar' ? 'primary' : 'cell4'" :click="() => display = 'calendar'" iconVal="mdi-calendar-month" carre color="cell5" />
                </v-col>
            </v-row>
        </div>

        <div v-if="activities.length > 0 && ready" style="overflow-y: auto; overflow-x: hidden; height: calc(100% - 65px)" id="scroll-target">
            <v-row v-show="display === 'list'" dense align="center" class="cell2" style="position: sticky; top: 0; z-index: 1;">
                <v-col style="max-width: 122px" class="pa-3 text-center">
                    {{ $t('single_trad.ChangeField.date') | firstCapital }}
                </v-col>
                <v-divider vertical />
                <v-col class="pa-3 text-center">
                    {{ $t('single_trad.ChangeField.message') | firstCapital }}
                </v-col>
                <v-divider vertical />
                <v-col class="pa-3 text-center" style="max-width: 140px">
                    {{ $tc('single_trad.ChangeField.changes', 2) | firstCapital }}
                </v-col>
                <v-col cols="12" class="pa-0">
                    <v-divider />
                </v-col>
            </v-row>
            <template v-if="display === 'list'" v-for="(item,i) in activities">
                <v-row  :key="i" dense :class="i % 2 !== 0 ? 'cell2':''" align="center">
                    <v-col style="max-width: 122px" class="pa-3">
                        {{ item['created'] | toLocalDate }}
                    </v-col>
                    <v-divider vertical />
                    <v-col class="pa-3">
                        {{ item['message'] }}
                    </v-col>
                    <v-divider vertical v-if="item['changes'] ? Object.keys(item['changes']).length > 0 ? true :false: false" />
                    <v-col v-if="item['changes'] ? Object.keys(item['changes']).length > 0 ? true :false: false" class="pa-3" style="max-width: 140px">
                        <ChangeField
                                v-model="item['changes']"
                                :expanded="true"
                                classes="pl-2"
                        />
                    </v-col>
                </v-row>
                <v-divider />
            </template>
            <Calendar
                    v-if="display === 'calendar'"
                    :items="calendar_activities"
                    :fields="calendar_fields"
                    :path="$route.path.replace('/dashboard', '')"
                    :embed="true"
                    :actions="[]"
                    :methods="[]"
                    :routeChildren="[]"
                    class="rounded-lg overflow-hidden"
            />
        </div>
        <div v-else-if="!ready" class="d-flex" style="height: calc(100% - 55px); flex-direction: column; align-items: center; justify-content: center">
            <div class="text-center">
                <v-progress-circular indeterminate size="40" color="primary"/>
            </div>
        </div>
        <div v-else class="text-center d-flex" style="height: calc(100% - 55px); flex-direction: column; align-items: center; justify-content: center">
            <div class="sub-title-1 grey--text mb-4" align="center" >{{ $tc('single_trad.Activities.no_act', 1) }}</div>
            <v-icon color="grey" large>mdi-folder-information</v-icon>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Activities",
        props: ['path', 'pevents', 'edit'],
        components: {
            Button: () => import("@/components/ui/Button.vue"),
            Calendar: () => import("@/components/Calendar.vue"),
            ChangeField: () => import('@/components/fields/ChangeField.vue')
        },
        data(){
            return {
                activities: [],
                calendar_activities: [],
                all_fields: [],
                display: 'list',
                calendar_fields: {},
                ready: false
            }
        },
        methods:{
            fetchEntityFields(){
                this.$wsc.getOptions(this.$route.params, {}, this.path + "/activity", fields => {
                    this.all_fields = fields.fields
                    this.calendar_fields = {
                        name: {...fields.fields.message},
                        changes: {...fields.fields.changes},
                        start: {...fields.fields.created},
                        end: {...fields.fields.created}
                    }
                    this.calendar_fields.start.label = this.$tc('global.action.start', 1)
                    this.calendar_fields.end.label = this.$tc('global.action.end', 1)
                }, fail => {

                })
            },
            fetchActivities(){
                this.ready = false

                this.$wsc.getList(this.path + "/activity", {ordering: '-created'}, activities => {
                    this.activities = activities
                    this.calendar_activities = activities.map((e)=>{
                        return {
                            name: e.message,
                            changes: e.changes,
                            start: e.created,
                            end: e.created,
                            type: {display_name:'All', id:'-1'}
                        }})
                    this.ready = true
                    this.$emit('loaded', this.$refs.child)
                }, fail => {

                })
            },
            scrollUp(){
                this.$nextTick(() => {
                    const container = document.getElementById('scroll-target')

                    if(container){
                        container.scrollTop = 0
                    }
                })
            },
            setUpHooks(){
                this.pevents.wait('update', () => {
                    this.fetchActivities()
                })
            }
        },
        created(){
            this.setUpHooks()
            this.fetchEntityFields()
            this.fetchActivities()
        }
    }
</script>
